<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <!--  -->
    <div class="card">
      <div class="card-body">
        <div class="row">
          <div class="col">
            <DxDataGrid id="gvHouses" ref="grid" v-bind="options" :data-source="housesData" :on-editor-preparing="onEditorPreparing">
              <DxHeaderFilter :visible="true" />
              <DxColumn data-field="streetName" caption="Улица">
                <DxRequiredRule />
              </DxColumn>
              <DxColumn data-field="houseNumber" caption="Дом">
                <DxRequiredRule />
              </DxColumn>

              <DxColumn data-field="buildingNumber" caption="Корп." />
              <DxColumn :visible="false">
                <DxFormItem :col-span="2" itemType="empty"> </DxFormItem>
              </DxColumn>

              <DxColumn :width="125" data-field="bailiffId" caption="ССП">
                <DxLookup :data-source="bailiffsStore" display-expr="name" value-expr="id" />
              </DxColumn>
              <DxColumn :width="125" data-field="courtId" caption="СУ">
                <DxLookup :data-source="courtsStore" display-expr="number" value-expr="id" />
              </DxColumn>

              <DxScrolling row-rendering-mode="virtual" />
              <DxExport fileName="Дома" :enabled="true" />
              <DxEditing :allow-adding="true" :allow-updating="true" mode="popup">
                <DxPopup height="auto" width="800" />
              </DxEditing>
              <DxFilterRow :visible="true" />
              <DxPaging :page-size="10" />
              <DxPager
                :visible="true"
                :allowed-page-sizes="[5, 10, 50]"
                :show-page-size-selector="true"
                :show-navigation-buttons="true"
                :show-info="true"
              />
            </DxDataGrid>
          </div>
        </div>
      </div>
    </div>
    <!--  -->
  </Layout>
</template>

<script>
import Layout from "../../layouts/main.vue";
import PageHeader from "@/components/page-header";

import { DxHeaderFilter, DxFormItem } from "devextreme-vue/data-grid";
import createStoreExtend from "@/helpers/grid/store";
import { authHeader } from "@/helpers/authservice/auth-header";
import settings from "@/helpers/grid/settings.js";
import { DxPopup, DxRequiredRule, DxExport } from "devextreme-vue/data-grid";

const housesData = createStoreExtend({
  key: "id",
  loadUrl: `${process.env.VUE_APP_URL}${process.env.VUE_APP_HOUSES_GET}`,
  insertUrl: `${process.env.VUE_APP_URL}${process.env.VUE_APP_HOUSES_CREATE}`,
  updateUrl: `${process.env.VUE_APP_URL}${process.env.VUE_APP_HOUSES_UPDATE}`,
  onBeforeSend: (_method, ajaxOptions) => {
    ajaxOptions.xhrFields = { withCredentials: true };
    ajaxOptions.headers = authHeader();
  },
  onAjaxError: (e) => {
    console.log(e);
  },
});

export default {
  components: {
    Layout,
    PageHeader,
    DxPopup,
    DxHeaderFilter,
    DxFormItem,
    DxRequiredRule,
    DxExport,
  },

  data() {
    return {
      title: "Дома",
      items: [
        {
          text: "Справочник",
          href: "/",
        },
        {
          text: "Дома",
          active: true,
        },
      ],

      options: settings,
      housesData,
      bailiffsStore: createStoreExtend({
        key: "id",
        loadUrl: `${process.env.VUE_APP_URL}${process.env.VUE_APP_BAILIFFS_GET}`,
      }),
      courtsStore: createStoreExtend({
        key: "id",
        loadUrl: `${process.env.VUE_APP_URL}${process.env.VUE_APP_COURTS_GET}`,
      }),
    };
  },
  methods: {
    onEditorPreparing(e) {
      e.editorOptions.showClearButton = true;
    },
  },
};
</script>

<style></style>
